import { useEffect, useRef, useState } from "react";
import VehicleCardLanding from "./VehicleCardLanding/VehicleCardLanding";
import VehicleCardForms from "./VehicleCardForms/VehicleCardForms";
import VehicleCardSuccess from "./VehicleCardSuccess/VehicleCardSuccess";
import "./vehicleCard.css";
import fetchClient from "../../services/fetch";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleList, setLoading, setToReschedule } from "../../redux-sagas/actions";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import addToDataLayer from "../../utils/addToDataLayer";
import useValidateForm from "../../hooks/useValidateForm";
import getUtmParams from "../../utils/getUtmParams";
import { getVehiclesParams } from "../../utils/getParams";

const VehicleCard = ({ idx, item, date, handleOpenDropdown, queryParams }) => {
  const { t } = useTranslation();
  const isSubmit = useRef(false);
  const dispatch = useDispatch();
  const toReschedule = useSelector((state) => state.toReschedule);
  const settings = useSelector((state) => state.settings);
  const documentTypes = useSelector((state) => state.documentTypes);
  const brandSettings = settings?.brandGroupConfiguration?.brandConfiguration;

  const [payload, setPayload] = useState({});
  const [bookingError, setBookingError] = useState("");
  const [cardType, setCardType] = useState("landing");
  const [cardSelected, setCardSelected] = useState(false);
  const [bookingData, setBookingData] = useState({});

  const { errors, validateOnChange, validateOnSubmit, resetErrors } = useValidateForm();

  useEffect(() => {
    if (toReschedule) {
      const { firstname, lastname, documentTypeId, documentNumber, email, phoneNumber } =
        toReschedule;

      setPayload((prevState) => ({
        ...prevState,
        firstname,
        lastname,
        documentNumber,
        documentTypeId,
        email,
        phoneNumber
      }));
    } else
      setPayload(
        documentTypes.data.length > 1 ? {} : { documentTypeId: documentTypes.data[0]?.id }
      );
  }, [toReschedule]);

  useEffect(() => {
    if (
      documentTypes.data.length &&
      !payload?.documentTypeId &&
      !toReschedule &&
      documentTypes.data.length === 1
    )
      setPayload({ ...payload, documentTypeId: documentTypes.data[0].id });
  }, [documentTypes.data]);

  useEffect(() => {
    if (!Object.keys(errors).length && isSubmit.current) finishSubmit();
  }, [errors]);

  useEffect(() => {
    setCardType("landing");
  }, [date]);

  const toggleHour = (timeslot) => {
    setCardType("form");
    setCardSelected(!cardSelected);
    setPayload({
      ...payload,
      startTime: timeslot.startTime,
      endTime: timeslot.endTime,
      bookingDate: item.bookingDate
    });
  };

  const handleBack = () => {
    setCardType("landing");
    setCardSelected(!cardSelected);
    setBookingError();
    resetErrors();
    if (!toReschedule)
      setPayload({
        documentTypeId: documentTypes.data.length > 1 ? null : documentTypes.data[0].id
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const obj = validateOnChange(name, value, payload);
    if (obj.isValid) setPayload(obj.newPayload);

    if (name === "documentTypeId")
      setPayload((prevPayload) => ({ ...prevPayload, documentNumber: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateOnSubmit(payload, "booking");
    isSubmit.current = true;
  };

  const finishSubmit = () => {
    const obj = {
      ...payload,
      vehicleId: item.vehicleId,
      showroomId: item.showroomId,
      ...getUtmParams().payload
    };
    dispatch(setLoading(true));
    (toReschedule
      ? fetchClient().put(`/testdrives/${toReschedule.bookingId}`, obj)
      : fetchClient().post("/testdrives", obj)
    )
      .then((res) => {
        addToDataLayer(brandSettings, {
          ...obj,
          modelName: item.modelName,
          variantName: item.variantName,
          showroomName: item.showroomName,
          dateFormatted: res.data.data.bookingDateFormatted,
          timeFormatted: res.data.data.startTimeFormatted
        });
        isSubmit.current = false;
        dispatch(setLoading(false));
        setBookingData(res.data.data);
        if (toReschedule) dispatch(setToReschedule(null));
        setCardType("success");
      })
      .catch((err) => {
        console.log(err);
        setBookingError(err.response.data.message);
      })
      .finally(() => {
        dispatch(getVehicleList(getVehiclesParams(queryParams)));
      });
  };

  return (
    <div className={`vehicle-card ${cardSelected ? "selected" : ""}`}>
      {cardType === "landing" ? (
        <VehicleCardLanding
          idx={idx}
          item={item}
          toggleHour={toggleHour}
        />
      ) : (
        <div className={`form-success ${cardType === "form" ? "form" : "success"}`}>
          {cardType === "form" && (
            <div className='form-heading'>
              <h5>{getTranslation("Schedule Test Drive", t)} - </h5>
              <span>{getTranslation("Fill in contact information", t)}</span>
            </div>
          )}
          <div>
            <div className='model'>
              <img
                src={item.vehicleImageUrl}
                alt={`${item.vehicleName} display img`}
              />
              <span className='model-name'>{item.brandName}</span>
              <p>{item.modelName}</p>
              <span className='vehicle-model'>
                {getTranslation("Vehicle", t)}:
                <p>
                  {item.brandName} - {item.modelName}
                </p>
              </span>
            </div>
            {cardType === "form" ? (
              <VehicleCardForms
                handleBack={handleBack}
                item={item}
                payload={payload}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleOpenDropdown={handleOpenDropdown}
                bookingError={bookingError}
              />
            ) : (
              <VehicleCardSuccess
                handleBack={handleBack}
                bookingData={bookingData}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleCard;
